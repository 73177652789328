<template>

  <div class="section" data-anchor="question3">
    <h1>Who’s behind
      Kuma karuta?</h1>
    <a href="#section2"><img src="/assets/images/bear_overview3.jpg" alt="overview" class="page-image"></a>

    <div class="page-center">
      <ul>
        <li>Peter Werner</li>
        <li>Martin Köglmeier</li>
        <li>Yoko Köglmeier</li>
      </ul>
      <p>any questions, comments &
        Suggestions, contact us:
        <a href="mailto:enjoy@kumakaruta.com">enjoy@kumakaruta.com</a>
      </p>
    </div>

  </div>

</template>

<script>

export default {
  name: 'ThirdQuestion'
}

</script>

<style scoped>
</style>

