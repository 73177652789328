<template>

  <div class="section" data-anchor="question1">
    <h1>Why Kuma karuta?</h1>
    <a href="#section2"><img src="/assets/images/bear_overview1.jpg" alt="overview" class="page-image"></a>
    <ol class="page-center">
      <li>Why shall we play Kuma karuta?
        <p>Kuma karuta aims to improve your wellbeing by playfully implementing a variety of short actiivities.
          Wellbeing can be split into seven areas such as creative, mental, physical, social, creative, emotional,
          sensory and spiritual. Kuma karuta suggests seven intensity levels of activities in each area, to try out,
          inspire and challenge yourself. Get creative and find the best activities for your personal situation.</p>
      </li>
      <li>Why do we call it Kuma karuta?
        <p>The name Kuma karuta is derived from the japanese words „Kuma“ meaning „bear“ and „karuta“ meaning something
          like „playful cards“.
          By using playfull cards, we would like you to become like a bear, peaceful and protective like a teddybear, as
          well as strong and powerful like a grizzly.</p>
      </li>
    </ol>
  </div>

</template>

<script>

export default {
  name: 'FirstQuestion'
}

</script>

<style scoped>
</style>

