<template>

  <div class="section" data-anchor="question2">
    <h1>How to play Kuma karuta?</h1>
    <a href="#section2"><img src="/assets/images/bear_overview2.jpg" alt="overview" class="page-image"></a>
    <ul class="second-list page-center">
      <li>Play for yourself:<ol>
          <li>Once a day, be curious</li>
          <li>Shuffle the cards</li>
          <li>Pick one card</li>
          <li>Perform the activity</li>
          <li>Next day, same game</li>
        </ol>
      </li>
      <li>Options to A:<ul>
          <li>You can do it on weekly base & select
            7 cards to plan into your weekly schedule</li>
          <li>Play with family, friends or colleagues:
            Every day or on weekly base, one of or everyone
            Selects a card, shares with others & perf</li>
        </ul>
      </li>
    </ul>
  </div>

</template>

<script>

export default {
  name: 'SecondQuestion'
}

</script>

<style scoped>
.second-list {
  list-style-type: alpha;
}
</style>

