<template>
  <div class="container">
    <full-page ref="fullpage" :options="options" id="fullpage">
      <first-section @nextSection="nextSection" />
      <second-section />
      <first-question />
      <second-question />
      <third-question />
    </full-page>
  </div>
</template>

<script>

import FirstSection from '@/modules/landingPage/components/FirstSection'
import SecondSection from '@/modules/landingPage/components/SecondSection'
import FirstQuestion from '@/modules/landingPage/components/FirstQuestion'
import SecondQuestion from '@/modules/landingPage/components/SecondQuestion'
import ThirdQuestion from '@/modules/landingPage/components/ThirdQuestion'

export default {
  name: 'KumaLanding',
  components: {
    FirstSection,
    SecondSection,
    FirstQuestion,
    SecondQuestion,
    ThirdQuestion
  },
  methods: {
    nextSection() {
      this.$refs.fullpage.api.moveSectionDown()
    }
  }
}

</script>

<style>
@font-face {
  font-family: kidPrint;
  src: url("@/assets/fonts/KidprintMTProBold.OTF");
}

div {
  font-family: kidPrint;
}

h1 {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
}

.page-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.8em;
}

.page-image {
  width: 200px;
  position: absolute;
  right: 0;
  top: 0;
}

.fp-watermark {
  display: none !important;
}
</style>
