<template>

  <div class="section introduction" @click="$emit('nextSection')">
    <div class="start-frame"><button type="button" class="btn btn-light start-button">START</button></div>
  </div>

</template>

<script>

export default {
  name: 'FirstSection',
  emits: ['nextSection'],
}

</script>

<style scoped>
.introduction {
  background: url("@/assets/images/welcome_bear.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
}

.start-frame {
  position: absolute;
  top: 78%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.start-button {
  width: 180px;
  height: 120px;
  font-size: 1.3em;
}
</style>

