<template>

  <div class="section" data-anchor="section2">
    <h1>Welcome
      to
      Kuma karuta</h1>
    <img src="/assets/images/bear_home.jpg" alt="home" class="page-image">
    <ol class="page-center">
      <li><a href="#question1">Why Kuma karuta?</a></li>
      <li><a href="#question2">How to play?</a></li>
      <li><a href="#question3">Who’s behind Kuma karuta?</a></li>
    </ol>
  </div>

</template>

<script>

export default {
  name: 'SecondSection'
}

</script>

<style scoped>

</style>

